<template>
  <div class="leaflet-page">
    <img src="//download-cos.yofish.com/jz-gongjushiyebu/20220106152501913-bg.jpg"
      width="100%" alt="">
    <div class="submit-btn" @click="applyAction">
      <img src="~@/assets/images/leaflet/appy-btn.png" width="100%" alt="">
    </div>
  </div>
</template>
<script>
import { getPublicize, getUploadClickAction } from '@/api/base';
import { getAppStatus, callAppHandler } from '@/utils/app';

export default {
  name: 'SpdbPoster',
  data() {
    return {
      visible: false,
      phoneNumber: '',
      userId: getAppStatus().cuserId,
    };
  },
  watch: {
    visible(val) {
      if (!val) this.phoneNumber = '';
    },
  },
  mounted() {
    document.title = '浦发银行宣传页';
    this.userId = this.userId || sessionStorage.getItem('userId');
    // this.getPublicize();
    if (this.userId) {
      let isLogin = this.userId ? '已登录' : '未登录';
      window?._czc.push(['_setCustomVar', '是否登录', isLogin, 7200]);
    } else {
      callAppHandler('jzUserId', null, (res) => {
        sessionStorage.setItem('userId', res);
        this.userId = res;
        let isLogin = this.userId ? '已登录' : '未登录';
        window?._czc.push(['_setCustomVar', '是否登录', isLogin, 7200]);
      });
    }
  },
  computed: {
    phone() {
      return getAppStatus().phone;
    },
    userIds() {
      // "6bbf384d-dacd-472e-8c9f-d013c59d20b9"
      return getAppStatus().cuserId;
      // return '6bbf384d-dacd-472e-8c9f-d013c59d20b9';
    },
  },
  methods: {
    async getPublicize() {
      let res;
      let data = { paramV: 'dddd' };
      const { query } = this.$route;
      // data = JSON.stringify(data);
      try {
        res = await getPublicize(query);
      } catch (e) {
        console.log(e);
        this.$toast({
          content: e.desc,
        });
      }
      console.log(res);
      if (res.code === 1) {
        this.linkUrl = res?.results?.linkUrl;
        this.channel = res?.results?.channel;
        if (this.linkUrl) {
          window.open(this.linkUrl, '_self');
        }
      } else {
        this.$toast({ content: '网络异常!', duration: 2000 });
      }
    },
    async getUploadClickAction() {
      let res;
      const {
        phoneNumber, channel, userId, phone,
      } = this;
      const params = {
        phone: phoneNumber,
        channel,
      };
      if (userId && !phone) sessionStorage.setItem('userId', userId);
      try {
        res = await getUploadClickAction(params);
      } catch (e) {
        console.log(e);
        this.$toast({ content: '网络异常!', duration: 2000 });
        setTimeout(() => {
          this.visible = false;
        }, 300);
      }
      if (res.code === 1 && this.linkUrl) {
        this.visible = false;
        setTimeout(() => {
          window.open(this.linkUrl, '_self');
        }, 400);
      }
    },
    applyAction() {
      this.getPublicize();
    },
  },
};
</script>
<style lang="scss" scoped>
.leaflet-page {
  width: 100%;
  // overflow: hidden;
  position: relative;
  .head {
    width: 236px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -90px;
    z-index: 30;
  }
  .submit-btn {
    width: 690px;
    height: 110px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 59px;
  }
}
.apply-content {
  padding: 165px 49px 0 49px;
  .input {
    position: relative;
    width: 100%;
    height: 60px;
    // border-bottom: 2px solid #E5E4E4;
    input {
      border-bottom: 2px solid #E5E4E4;
    }
  }
  input {
    width: 100%;
    height: 55px;
    border: none;
    font-size: 28px;
    &::-webkit-input-placeholder {
      font-size: 28px;
      color: #CDCDCD;
    }
  }
}
.apply-btn {
  width: 538px;
  height: 79px;
  border: 2px solid #FFFFFF;
  border-radius: 40px;
  line-height: 79px;
  text-align: center;
  font-size: 47px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-style: italic;
  color: #FFFFFF;
  text-shadow: 0px 4px 5px rgba(13, 127, 134, 0.56);
  margin: 45px auto 0;
}
</style>
